import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { ThemeProvider } from 'styled-components'
import { setUserStyles } from 'src/Utils/Utilities'
import { getProfileFromMarketplace } from 'src/Helpers/Permissions'

/* Components */
import Loader from '../../Components/Loader/Loader'
import * as mixins from 'src/UIComponents/Mixins/Mixins'
import ErrorBoundary from 'src/Components/ErrorBoundary/ErrorBoundary'
import { Routes } from 'src/Router/Routes'
import { BrowserRouter } from 'react-router-dom'
import DisabledDashPage from '../../UIComponents/DisabledDashPage'
import appConfig from '../../Configs/appConfig'
import { getCustomByMarketplace } from '../../Actions/custom'

const MainScreen = () => {
  const dispatch = useDispatch()

  const regexp = new RegExp(`.*[.]${appConfig.domain}`)
  const hasSlug = regexp.test(window.location.hostname)

  const {
    temporaryColor,
    color,
    logo,
    favicon,
    marketplaceSlug,
    marketplaceRequesting,
    apiSettingsRequesting,
    disableDash,
    customRequesting,
    customSlugRequesting,
    isDetailsRequesting,
    marketplaceSuccess,
    marketplaceError,
    marketplaceContextChangeRequesting,
    sellerContextChangeRequesting,
    permissionProfile,
    isBackoffice
  } = useSelector(
    ({ marketplace, settings, custom, context, user, authentication }) => {
      setUserStyles(custom.temporaryColor || custom.color)

      let permissionProfile = null

      if (user.permissions && marketplace.id) {
        permissionProfile = getProfileFromMarketplace(
          marketplace.id,
          user.permissions
        )
      }

      return {
        temporaryColor: custom.temporaryColor,
        color: custom.color,
        logo: custom.logo,
        favicon: custom.favicon,
        slug: marketplace.slug,
        marketplaceRequesting: marketplace.isRequesting,
        apiSettingsRequesting: settings.isRequesting,
        disableDash: settings.items.disableDash,
        customRequesting: custom.isRequesting,
        customSlugRequesting: marketplace.customSlugRequesting,
        isDetailsRequesting: marketplace.isDetailsRequesting,
        marketplaceSuccess: marketplace.requestSuccess,
        marketplaceError: marketplace.requestError,
        marketplaceContextChangeRequesting:
          context.marketplaceContextChangeRequesting,
        sellerContextChangeRequesting:
          !context.seller && context.sellerContextChangeRequesting,
        isBackoffice: authentication.accessOrigin === 'backoffice',
        permissionProfile
      }
    }
  )

  useEffect(() => {
    if (
      hasSlug &&
      !marketplaceError &&
      !marketplaceSuccess &&
      !marketplaceSlug
    ) {
      dispatch(getCustomByMarketplace())
    }
  }, [])

  const requesting =
    marketplaceRequesting ||
    apiSettingsRequesting ||
    customRequesting ||
    customSlugRequesting ||
    isDetailsRequesting ||
    marketplaceContextChangeRequesting ||
    sellerContextChangeRequesting

  if (disableDash) {
    return <DisabledDashPage />
  }

  if (requesting) {
    return <Loader />
  }

  return (
    <ThemeProvider
      theme={{
        color: temporaryColor || color,
        logo,
        favicon,
        mixins
      }}
    >
      <ErrorBoundary
        permissionProfile={permissionProfile}
        isBackoffice={isBackoffice}
      >
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default MainScreen
