import { createStore, applyMiddleware } from 'redux'
import {
  persistStore,
  persistCombineReducers,
  createTransform,
  persistReducer
} from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

/* Persists */
import storage from 'redux-persist/lib/storage'

/* Middlerwares */
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import history from '../Configs/history'

/* Reducers and Actions */
import * as reducers from '../Reducers/reducers'
import onboarding from '../Reducers/onboarding'
import access from '../Reducers/access'

let anyMiddleware = () => (next) => (action) => {
  // Put any error middleware here
  next(action)
}

let authenticationTransform = createTransform(
  (inboundState) => {
    return omit(inboundState, [
      'authError',
      'authRequesting',
      'confirmUserRequesting',
      'confirmUserSuccess',
      'confirmUserError',
      'tokenValidateError',
      'userDeleteRequesting',
      'checkCredentialsRequesting',
      'checkCredentialsSuccess',
      'checkCredentialsError'
    ])
  },
  null,
  {
    whitelist: ['authentication']
  }
)

const exportsTransform = createTransform(
  (inboundState) => pick(inboundState, ['watchFiles']),
  null,
  {
    whitelist: ['exports']
  }
)

const marketplaceTransform = createTransform(
  (inboundState) =>
    pick(inboundState, ['id', 'lastSelectedMarketplaceId', 'rootDetails']),
  null,
  {
    whitelist: ['marketplace']
  }
)

const persistConfig = {
  key: 'zdash',
  storage,
  // Whitelabel will never be stored at front. Thats way we always can have the updated whitelabel data.
  whitelist: ['user', 'authentication', 'exports', 'marketplace'],
  transforms: [authenticationTransform, exportsTransform, marketplaceTransform]
}

const persistOnboardingConfig = {
  key: 'zdash:onboarding',
  storage
}

const persistAccessConfig = {
  key: 'zdash:access',
  storage
}

const persistedReducer = persistCombineReducers(persistConfig, {
  onboarding: persistReducer(persistOnboardingConfig, onboarding),
  access: persistReducer(persistAccessConfig, access),
  router: connectRouter(history),
  ...reducers
})

const middlewares = [thunk, anyMiddleware, routerMiddleware(history)]

export const panelStore =
  process.env.NODE_ENV === 'production'
    ? createStore(persistedReducer, {}, applyMiddleware(...middlewares))
    : createStore(
        persistedReducer,
        {},
        composeWithDevTools(applyMiddleware(...middlewares))
      )

export const persistor = persistStore(panelStore)
